.location {
  padding: 0% 10%;
  margin-top: 10px; }

.location + a {
  button {
    margin: 10%; } }

.location-hero-image {
  height: 480px;
  width: 1024px;

  img {
    width: 1800px;
    height: auto;
    max-height: 480px;
    object-fit: cover;
    object-position: 0% 11%; } }

.location-title {
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 1.5em;
  margin-bottom: 10%;
  margin-top: 14%; }

.location-hero-image + h2 {
  color: #000; }

h3 {
  text-align: left;
  font-weight: 300;
  letter-spacing: 3.75px; }

.store-front {
  align-self: flex-start;
  height: 311px;
  margin: auto;
  width: 248px;
  padding-bottom: 16%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.store-front-loc {
  height: 248px;
  width: 100%; }

.store-front-loc img {
  width: 100%;
  height: auto; }

.street-title {
  letter-spacing: 2.5px;
  margin-top: 0%; }

@media (min-width: 1024px) {

  .location {
    padding: 3% 10% 0%; }

  .location {
    display: flex;
    justify-content: center; }

  .hours {
    margin: 0; }

  .location-title {
    margin-top: 6.5%;
    padding-bottom: 0.5%; }

  .location img {
    position: relative;
    right: 52px; }

  .store-front {
    height: 350px;
    margin: 0px;
    width: 350px; }

  .location + a {
    button {
      margin: 6% 0% 0.5% 0%; } } }


