.service-carousel {
  height: auto;
  width: 100%;
  text-align: left;
  padding: 10% 10% 0% 10%; }

.service-carousel + a {
  button {
    margin-top: 19%;
    margin-bottom: 19%; } }

.bluebck-whitetxt {
  background-color: #83929C;
  color: #fff;

  .slick-dots li button::before {
    border-color: #fff; }

  .slick-dots li.slick-active button:before {
    color: #fff;
    background-color: #fff; } }



.whitebck-blacktxt {
  background-color: #fff;
  color: #000;

  .slick-dots li button::before {
    border-color: #000; }

  .slick-dots li.slick-active button:before {
    color: #000;
    background-color: #000; } }



.bluebck-whitetxt, .whitebck-blacktxt {
  .title {
    margin: 0;
    padding-bottom: 1%;
    text-align: center; }

  h3 {
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 1.5em; }

  h4 {
    letter-spacing: 2px;
    margin-top: 35px; }

  h5 {
    font-style: italic;
    font-size: 1em;
    margin-bottom: 10px;
    margin-top: 15%;
    font-weight: 300; } }

.service-name {
  margin-bottom: 20px; }

.service-carousel .slick-dots li button::before {
  margin-top: 23px;
  line-height: 0;
  content: "";
  border: .5px solid;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  opacity: 1; }

.service-carousel .slick-dots li.slick-active button:before {
  opacity: 1; }

