h2 {
  color: white;
  margin: 0;
  padding: 0 30px 42px; }

.fixed {
  opacity: .2;
  transition: opacity .8s; }

.home {
  padding: 0;

  .location {
    padding-bottom: 15%; } }

.home-motto-image {
  width: 77%;
  height: 288px;
  margin: auto;
  padding: 11% 0%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.home-motto {
  font-weight: 300;
  margin: 0% 10% 17%;
  text-align: left; }

.location-title {
  text-align: center; }

.social-media {
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 1.5em;
  p {
    margin: 0; } }

@media (min-width: 1024px) {
  .home-image-and-motto {
    display: flex;
    height: 386px; }

  .home-opener {
    height: 480px;
    font-size: 44px; }

  .home-motto-image {
    height: 386px;
    padding: 4.5% 0% 4.5% 5%;
    width: 592px;

    img {
      height: 360px; } }

  .home-motto {
    width: 432px;
    padding: 76px 112px 0px 72px;
    margin: 0px; }

  .store-front {
    height: 312px;
    width: 541px;
    padding: 0%;

    img {
      height: auto; } }

  .location-title {
    margin-top: 6%;
    margin-bottom: 0%; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }
