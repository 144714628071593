.services {
  background: #A0AF83;
  color: white;
  overflow: scroll;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 72px;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;

  h3 {
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 1.5em; }

  h4 {
    letter-spacing: 2px;
    margin-top: 35px;
    width: auto; }

  h5 {
    font-style: italic;
    font-size: 1em;
    margin-bottom: 10px;
    font-weight: 300; }

  .title {
    margin: 0;
    padding-bottom: 20px;
    text-align: center; } }

.services-container {
  display: flex;
  overflow: scroll;
  gap: 60px; }

.special-pricing {
  text-align: right;
  margin-right: 6px; }

.column {
  width: 100%; }
