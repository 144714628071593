.add-on {
  margin-bottom: 20px; }

.service-name p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: .9em; }

.service-name .add-on {
  margin-bottom: 20px; }

.name {
  width: 90%;
  display: inline-block; }

.cost {
  width: 10%;
  display: inline-block; }

.services .cost p {
  justify-content: center;
  padding-left: 35px; }
