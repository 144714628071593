hr {
  border-top: 0.5px solid #D3D3D3;
  margin-bottom: 20px; }

.address {
  margin: 0;
  text-align: left; }

.address-card h3 {
  font-weight: 900; }

.days {
  font-style: italic;
  margin-bottom: 0%; }

.hours {
  font-weight: 200;
  margin-bottom: 10%; }

.hours, .days {
  margin-top: 0%;
  text-align: left; }

.phone {
  padding-top: 5%; }

@media (min-width: 1024px) {

  .days {
    margin-bottom: 0; }

  .interior-pics {
    padding-top: 7%; }

  .phone {
    padding-top: 10%;
    padding-bottom: 5%; }

  .street-title {
    margin: 0; } }
