.top-nav-panel {
  width: 800px;
  display: flex;
  justify-content: space-around; }

.top-nav-link {
  color: black;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none; }

.top-nav-link:after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background-color: gold;
  transition: width .3s; }

.top-nav-link:hover:after {
  width: 100%;
  transition: width .3s; }



