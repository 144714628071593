* {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  height: 100%; }

body.side-panel-open {
  overflow: hidden; }

.App {
  text-align: center;
  height: 100%; }
