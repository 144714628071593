.side-nav-panel {
  height: 100%;
  background-color: #000;
  opacity: 0;
  padding-top: 25px;
  position: fixed;
  transition: opacity 1s ease-in-out;
  width: 100vw;
  z-index: 1;

  a {
    color: white;
    font-size: 1.5rem;
    text-decoration: none; } }

ul {
  list-style-type: none;
  text-align: left;
  margin: 0; }

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em; }

li {
  margin-bottom: 1em; }

.home {
  padding-top: 1em; }

.side-nav-panel.open {
  opacity: 0.9; }

.side-nav-panel li {
  opacity: 0;
  position: relative;
  text-transform: uppercase; }

.side-nav-panel.open li {
  animation: fadeInRight .5s ease-in-out .35s forwards; }

.side-nav-panel.open li:nth-of-type(2) {
  animation-delay: .4s; }

.side-nav-panel.open li:nth-of-type(3) {
  animation-delay: .45s; }

.side-nav-panel.open li:nth-of-type(4) {
  animation-delay: .50s; }

.side-nav-panel.open li:nth-of-type(5) {
  animation-delay: .55s; }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%; }

  100% {
    opacity: 1;
    left: 0; } }
