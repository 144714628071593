.footer {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  height: 250px;
  justify-content: space-around;
  align-items: center;
  letter-spacing: 3px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px; }

.r {
  width: 350px; }

.contact-information {
  width: 350px; }

.footer-address {
  text-align: center;
  margin: 0; }

.footer-phone {
  padding-top: 2%; }

.links {
  color: #fff;
  margin: 0px 13px; }

.logo {
  filter: invert(100%);
  width: 10%; }

.social-media-links {
  margin-top: 0px;
  width: 250px; }

@media only screen and (max-width: 375px) and (min-width: 320px) {
  .footer {
    flex-direction: column; }

  .street {
    letter-spacing: 8px; }

  .city {
    letter-spacing: 4px; }

  .footer-phone {
    margin-top: -8px; }

  .social-media-links {
    margin-top: 25px; } }

@media only screen and (max-width: 768px) and (min-width: 375px) {
  .footer {
    flex-direction: column; }

  .contact-information {
    margin-top: -5px; }

  .street {
    letter-spacing: 7px; }

  .city {
    letter-spacing: 4px; }

  .footer-phone {
    letter-spacing: 4px;
    margin-top: -8px; }

  .social-media-links {
    margin-top: 30px; } }






