.loc-caption {
  text-align: center;
  padding: 4% 0% 2%; }

.map-desktop {
  width: 42.4%; }

.map-desktop img {
  height: auto;
  width: 100%; }

.map-mobile {
  margin: 20% auto 13%;
  width: 97%; }

.map-mobile img {
  height: auto;
  width: 100%; }
