.opener {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 394px; }

.hero-text {
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 33%; }

.hero-text h2 {
  color: white;
  margin: 0;
  padding: 0 10% 42px; }

.home-background {
  background-image: url("../../assets/home-hero.jpg"); }

.locations-background {
  background-image: url('../../assets/home-location.jpg'); }

.services-background {
  background-image: url('../../assets/nailPolishPic.jpg'); }

.location-page-building-image {
  img {
    width: 100%;
    height: 248px; } }

@media (min-width: 1024px) {
  h2 {
    font-size: 2.75em; }

  .opener {
    height: 480px; }

  .hero-text {
    padding-top: 17%; } }
