.black {
  color: #fff;
  background-color: #000; }

.blue {
  color: #fff;
  background-color: #4B637D;
  border-radius: 2px; }


.book-appointment-button {
  font-size: 1em;
  padding: 15px 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  border: none;
  letter-spacing: 2px;
  margin-bottom: 20px;
  transition: color 750ms, background-color 750ms, border-radius 750ms; }

.book-appointment-button:hover {
  background-color: #758BA3;
  color: white;
  cursor: pointer; }

.narrow {
  width: 235px; }

.white {
  color: #000;
  background-color: #fff; }

.wide {
  width: 248px; }
