.polish-display {
  background: url('../../assets/services-hero.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 19%;
  opacity: 0.8; }

.appointment-button-services {
  min-height: 128px; }

.appointment-button-services + image-carousel {
  margin-bottom: 0%; }

@media (min-width: 1024px) {
  .polish-display {
    height: 480px; } }
