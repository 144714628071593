img.main-logo {
  height: 60%; }

.laid-out-menu {
  width: 800px;
  display: flex;
  justify-content: space-around; }

.laid-out-links {
  font-size: 1.5rem;
  font-weight: 700; }

.a {

  .main-logo {
    pointer-events: none; }

  .main-logo.selected {
    opacity: .2; } }

.nav-bar {
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  height: 93px;
  justify-content: space-between;
  padding: 0 21px;
  position: sticky;
  top: 0;
  z-index: 1; }

.nav-bar.selected {
  background: rgba(0, 0, 0, 0.6); }

.hamburger-menu {
  padding-right: 15px;
  cursor: pointer; }

.hamburger-menu .line {
  background-color: #000;
  width: 45px;
  height: 2px;
  display: block;
  margin: 8px auto; }

.hamburger-menu .line.selected {
  background: #fff; }

.nav-bar.home-path .line {
  background: #000; }

.line.selected.one, .line.selected.three {
  background: #fff; }

.line.one {
  margin-top: 0; }

.line.three {
  margin-bottom: 0; }

.selected.one {
  transition: all 0.3s ease-in-out;
  transform: translateY(13px) rotate(45deg); }

.selected.two {
  transition: all 0.3s ease-in-out;
  opacity: 0; }

.selected.three {
  transition: all 0.3s ease-in-out;
  transform: translateY(-8px) rotate(-45deg); }

.smoke-nav-bar {
  position: relative; }

.one {
  transition: all 0.3s ease-in-out; }

.two {
  transition: all 0.3s ease-in-out;
  opacity: 1; }

.three {
  transition: all 0.3s ease-in-out; }

