.interior-pics h3 {
  text-align: center; }

.large-pic img {
  height: 100%;
  width: 100%; }

.small-pics {
  height: 117px;
  justify-self: center;
  margin-bottom: 3.5%;
  width: 117px; }

.small-pics-container {
    margin: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    width: 244px; }

.small-pics {

  img {
    height: 100%;
    width: 100%; } }

@media (min-width: 1024px) {

  .large-pic {
    margin: auto;
    width: 47%; }

  .small-pics {
    height: 222px;
    justify-self: center;
    margin-bottom: 3.5%;
    width: 222px; }

  .small-pics-container {
    margin: 50px auto;
    width: 1000px; } }

