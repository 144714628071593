.team-page {

  .book-appointment-button {
    margin-bottom: 18%; }

  h2 {
    color: black;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 3.75px;
    padding: 4% 0% 2%;
    text-transform: uppercase; }

  p {
    font-weight: 300;
    padding: 0px 13% 16%;
    text-align: left;
    letter-spacing: 2px; } }

.team-pic {
  width: 100%;
  height: 248px;
  opacity: 0.9;

  img {
    width: 100%;
    height: auto; } }

@media (min-width: 1024px) {
  .team-pic {
    height: 480px;
    width: 100%;

    img {
      max-height: 480px;
      object-fit: cover; } }

  .team-page {

    p {
      padding: 0% 27% 4%; }

    .book-appointment-button {
      margin-bottom: 9%; } } }
