.carousel-inner {
  display: flex;
  margin-top: 42px; }

.image-carousel {
  background: #F7F5F2;
  height: 460px;
  padding-top: 17%;
  padding-bottom: 144%; }


.image-slider {
  background-color: #F7F5F2;
  height: 275px;
  margin: auto;
  width: 90%; }


.image-slider .slick-dots {
  bottom: -120px; }

.instagram-pics-header {
  padding: 0px 78px;
  margin-bottom: 40px; }

.insta-images {
  height: auto;
  margin: auto;
  max-height: 248px;
  width: 248px;
  margin-top: 20px; }

.image-carousel .slick-dots li.slick-active button:before {
  color: #000;
  opacity: 1;
  background-color: #000; }

.image-carousel .slick-dots li button::before {
  margin-top: 23px;
  line-height: 0;
  content: "";
  border: .5px solid #000;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  opacity: 1; }

@media (min-width: 1024px) {
  .carousel-inner {
    margin-top: 32px; }
  .image-carousel {
    height: 437px;
    padding-top: 65px;
    padding-bottom: 35%;
    margin-bottom: 3%; }
  .image-slider {
    height: 205px; }
  .insta-images {
    height: 240px;
    width: 236px; } }

